import { Box, Center, Text } from '@chakra-ui/react';
import {
  Button
} from "@chakra-ui/react";
import { Link } from 'react-scroll';

const ImageOverlayTextComponent = () => {

    var TxtType = function(el, toRotate, period) {
        this.toRotate = toRotate;
        this.el = el;
        this.loopNum = 0;
        this.period = parseInt(period, 10) || 2000;
        this.txt = '';
        this.tick();
        this.isDeleting = false;
    };

    TxtType.prototype.tick = function() {
        var i = this.loopNum % this.toRotate.length;
        var fullTxt = this.toRotate[i];

        if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
        } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
        }

        this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';

        var that = this;
        var delta = 200 - Math.random() * 100;

        if (this.isDeleting) { delta /= 2; }

        if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
        } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
        }

        setTimeout(function() {
        that.tick();
        }, delta);
    };

    window.onload = function() {
        var elements = document.getElementsByClassName('typewrite');
        for (var i=0; i<elements.length; i++) {
            var toRotate = elements[i].getAttribute('data-type');
            var period = elements[i].getAttribute('data-period');
            if (toRotate) {
              new TxtType(elements[i], JSON.parse(toRotate), period);
            }
        }
        // INJECT CSS
        var css = document.createElement("style");
        css.type = "text/css";
        css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
        document.body.appendChild(css);
    };

  return (
    <Box pos="relative" h="60vh" overflow="hidden">
      {/* Background Image */}
      <Box pos="absolute" top="0" left="0" w="100%" h="650px" bgImage="url('golf_banner.jpg')" bgSize="cover" zIndex={-1}></Box>

      {/* Overlay Text */}
      <Center pos="relative" top="25%" left="50%" transform="translate(-50%, -50%)" color="white" textAlign="center">
        <Text fontSize="40px" fontWeight="bold">
          Elevate your game<br />
          @ The Birdie House 
        </Text>
      </Center>
      <Center pos="relative" top="30%" left="50%" transform="translate(-50%, -50%)" color="white" textAlign="center">
      <Link activeClass="active" smooth spy to="overview">
      <Button
        size='md'
        height='48px'
        width='200px'
        border='2px'
        borderColor='#18A558'
        backgroundColor="#87CEEB"
      >
        Get Started
      </Button>
      </Link>
      <Button
        size='md'
        height='48px'
        width='200px'
        border='2px'
        borderColor='#87CEEB'
        backgroundColor="#18A558"
        marginLeft={5}
      >
        Schedule a Tour
      </Button>
      </Center>

    </Box>
  );
};

export default ImageOverlayTextComponent;
