// Dashboard.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import HeaderDashboard from '../components/HeaderDashboard';
import NavBarFooterDashboard from '../components/NavBarFooterDashboard';
import FacilityList from '../components/FacilityList';

const Facility = () => {
  const [message, setMessage] = useState('');
  const navigate = useNavigate()

  const baseURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_BASE_URL : 'http://localhost:3001' ;
  
  useEffect(() => { 
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/dashboard`, {
          withCredentials: true,
        })
        setMessage(response.data.message);
        
      } catch (error) {
        if (error.response && error.response.status === 401) {
          alert(error.response.data.message)
          navigate('/auth');
        } else {
          console.error('Error:', error.response.data.message);
        }
      }
    };
    fetchData();
  }, [navigate]);

  return (
    <>
      <HeaderDashboard message={message} />
      <FacilityList />
      <NavBarFooterDashboard />
    </>
  );
};

export default Facility;
