import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CartList from '../components/CartList';
import NavBarFooterDashboard from '../components/NavBarFooterDashboard';

const Checkout = () => {
  const [message, setMessage] = useState('');
  const [responseData, setResponseData] = useState(null);
  const navigate = useNavigate()

  const baseURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_BASE_URL : 'http://localhost:3001' ;
  
  useEffect(() => { 
    const fetchData = async () => {  
      try {
        const response = await axios.get(`${baseURL}/api/checkout`, {
          withCredentials: true,
        })
        setMessage(response.data.message);

        let responseData = response.data.data
        console.log("response data is ", responseData)
        setResponseData(responseData);
        
      } catch (error) {
        if (error.response && error.response.status === 401) {
          alert(error.response.data.message)
          navigate('/auth');
        } else {
          console.error('Error:', error.response.data.message);
        }
      }
    };
    fetchData();
  }, [navigate]);

  return (
    <>
      <CartList data={responseData} />
      <NavBarFooterDashboard />
    </>
  );
};

export default Checkout;