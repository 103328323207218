import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { FaHome, FaRegCalendarAlt, FaLock, FaBuilding, FaUser } from "react-icons/fa";
import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from "react";

export default function NavBarFooterDashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedIcon, setSelectedIcon] = useState(location.pathname);

  const iconStyle = (icon) => selectedIcon === icon ? { color: "#276749", transitionDelay: "0.1s, 0.1s" } : {};

  return (
    <Box className="wrapper" position="fixed" width="100%" bottom="0">
      <Flex className="navbar" background="#68D391" justifyContent="space-between" padding="10px 10px 15px">
        <Flex as="div" className="navbar__item -blue" width="70px" height="70px" cursor="pointer" padding="10px" alignItems="center" justifyContent="center" fontSize="25px" position="relative" transition="all .5s cubic-bezier(0.71, 0.03, 0.23, 0.95)" zIndex="2" color="#fff" direction="column" onClick={() => {setSelectedIcon('/dashboard'); navigate('/dashboard')}}>
          <Icon as={FaHome} className="navbar__icon" color={iconStyle('/dashboard').color} />
          <Text fontSize="13px" color={iconStyle('/dashboard').color}>Home</Text>
        </Flex>
        <Flex as="div" className="navbar__item -green" width="70px" height="70px" cursor="pointer" padding="10px" alignItems="center" justifyContent="center" fontSize="25px" position="relative" transition="all .5s cubic-bezier(0.71, 0.03, 0.23, 0.95)" zIndex="2" color="#fff" direction="column" onClick={() => {setSelectedIcon('/facility'); navigate('/facility');}}>
          <Icon as={FaBuilding} className="navbar__icon" color={iconStyle('/facility').color} />
          <Text fontSize="13px" color={iconStyle('/facility').color}>Facility</Text>
        </Flex>
        <Flex as="div" className="navbar__item -purple" width="70px" height="70px" cursor="pointer" padding="10px" alignItems="center" justifyContent="center" fontSize="25px" position="relative" transition="all .5s cubic-bezier(0.71, 0.03, 0.23, 0.95)" zIndex="2" color="#fff" direction="column" onClick={() => {setSelectedIcon('/booking'); navigate('/booking');}}>
          <Icon as={FaRegCalendarAlt} className="navbar__icon" color={iconStyle('/booking').color} />
          <Text fontSize="13px" color={iconStyle('/booking').color}>Booking</Text>
        </Flex>
        <Flex as="div" className="navbar__item -red" width="70px" height="70px" cursor="pointer" padding="10px" alignItems="center" justifyContent="center" fontSize="25px" position="relative" transition="all .5s cubic-bezier(0.71, 0.03, 0.23, 0.95)" zIndex="2" color="#fff" direction="column" onClick={() => {setSelectedIcon('/personal'); navigate('/personal');}}>
          <Icon as={FaUser} className="navbar__icon" color={iconStyle('/personal').color} />
          <Text fontSize="13px" color={iconStyle('/personal').color}>Personal</Text>
        </Flex>
      </Flex>
    </Box>
  );
};