import React from "react";

import Navbar from '../components/Navbar';
import ImageOverlayTextComponent from "../components/ImageOverlayText";
import PricingComponent from '../components/Pricing';
import OverviewContentComponent from '../components/OverviewContent';
import ContentHeaderComponent from '../components/ContentHeader';

const Home = () => {

  return (
      <>
      <Navbar />
      <ImageOverlayTextComponent />
      <ContentHeaderComponent />
      <OverviewContentComponent />
      <PricingComponent />
      </>
  );
};
export default Home;