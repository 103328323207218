import React, { useState, useEffect } from 'react';
import { Box, SimpleGrid, Flex, chakra, Button, Spinner, Badge } from '@chakra-ui/react';
import moment from 'moment';
import { IoMdTime } from "react-icons/io";
import { FaShoppingCart } from "react-icons/fa";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const TimeSlots = ({ selectedDate, selectedBay }) => {
  const [times, setTimes] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 6;
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedDate && selectedBay) {  
      const selectedDateISO = new Date(selectedDate).toISOString().split('T')[0];  
      setIsLoading(true);
      setSelectedTimes([]);
      const baseURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_BASE_URL : 'http://localhost:3001' ;
  
      axios.get(`${baseURL}/api/timeslots`, {
        params: {
          date: selectedDateISO,
          bay: selectedBay
        },
        withCredentials: true,
      })
      .then(response => {
        const isToday = moment().isSame(moment(selectedDateISO), 'day');
        const currentHour = isToday ? moment().startOf('hour').hour() : 0;
        const fetchedTimes = response.data.data.data
          .filter(time => time.Hour >= currentHour)
          .map(time => {
            const start = moment({hour: time.Hour}).format('h:mmA');
            const end = moment({hour: time.Hour}).add(1, 'hour').format('h:mmA');
            return {
              time: `${start}-${end}`,
              isAvailable: time.isAvailable
            };
          });
        setTimes(fetchedTimes);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);
      });
    }
  }, [selectedDate, selectedBay]);

  const handleTimeClick = (hour) => {
    if (selectedTimes.includes(hour)) {
      const newSelectedTimes = selectedTimes.filter(h => h !== hour);
      setSelectedTimes(newSelectedTimes);
    } else {
      const newSelectedTimes = [...selectedTimes, hour];
      setSelectedTimes(newSelectedTimes);
    }
  };

  const handleNext = () => {
    if ((page + 1) * itemsPerPage < times.length) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 0 && (page - 1) * itemsPerPage < times.length) {
      setPage(page - 1);
    }
  };

  const paginatedTimes = times.slice(page * itemsPerPage, (page + 1) * itemsPerPage);

  const handleCartClick = () => {
    const baseURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_BASE_URL : 'http://localhost:3001' ;

    axios.post(`${baseURL}/api/cart`, {
      times: selectedTimes,
      bay: selectedBay,
      date: selectedDate
    }, {
      withCredentials: true,
    })
    .then(response => {
      navigate('/checkout');
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box paddingBottom="250px" textAlign="center">
      <Flex justifyContent="space-between" marginTop={3}>
        <Flex>
          <IoMdTime fontSize={25} />
          <chakra.span> Select available times</chakra.span>
        </Flex>
        <Flex position="sticky" top="80px" right="0" marginRight="10%">
            {selectedTimes.length > 0 && (
                <>
                <chakra.div 
                    display="flex" 
                    alignItems="center" 
                    justifyContent="center" 
                    borderRadius="50%" 
                    background="#d1f1de"
                    border="1px solid #68D391"
                    width="40px" 
                    height="40px"
                    onClick={handleCartClick}
                >
                    <FaShoppingCart color="darkgreen" fontSize={25} />
                </chakra.div>
                <Badge position="absolute" top="-15px" right="-8px" borderRadius="50%" p="4px" fontSize="0.8em" background="#e5c0c0">{selectedTimes.length}</Badge>
                </>
            )}
        </Flex>
      </Flex>
      <Flex marginTop={2}>
        <Button onClick={handlePrevious} disabled={page === 0}>&lt;</Button>
        <chakra.span padding="8px">Time {page + 1}</chakra.span>
        <Button onClick={handleNext}>&gt;</Button>
      </Flex>
      <Box>
        <Flex justify="center" align="center" marginTop={3}>
          <SimpleGrid width="350px" height="100px" columns={2} spacing={3} fontSize="sm">
            {paginatedTimes.map((timeObj, index) => {
              const hour = moment(timeObj.time, 'h:mm A').hour();
              return (
                <Box 
                  key={hour}
                  bg={selectedTimes.includes(hour) ? "#d1f1de" : "#fff"} 
                  color="black" 
                  p={5} 
                  borderRadius="md" 
                  border="1px solid #68D391" 
                  shadow="md"
                  onClick={() => timeObj.isAvailable && handleTimeClick(hour)}
                  opacity={timeObj.isAvailable ? 1 : 0.5}
                >
                  {timeObj.time}
                </Box>
              );
            })}
          </SimpleGrid>
        </Flex>
      </Box>
    </Box>
  );
};

export default TimeSlots;