import React from "react";

import OTPForm from "../components/OTPForm";


const Verify = () => {

  return (
      <>
      <OTPForm />
      </>
  );
};
export default Verify;