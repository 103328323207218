import { Box, Text, Button, IconButton, Stack } from "@chakra-ui/react";
import { DeleteIcon,TimeIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";


const ShoppingCart = ({ data }) => {
    const [cartItems, setCartItems] = useState([]);
    const [total, setTotal] = useState(0);

    const navigate = useNavigate();
  

    const handleContinueBooking = () => {
        navigate("/booking"); // replace "/booking" with the path to your booking page
    };
  
    useEffect(() => {
      if (data) {
        setCartItems(data.times.map(time => {
          const startHour = time; // assuming 'time' is a number representing the hour
          const endHour = (startHour + 1) % 24;
          const formattedTime = `${startHour === 0 || startHour === 12 ? 12 : startHour % 12}${startHour < 12 || startHour === 24 ? 'am' : 'pm'}-${endHour === 0 || endHour === 12 ? 12 : endHour % 12}${endHour < 12 || endHour === 24 ? 'am' : 'pm'}`;
          return { name: `${formattedTime}`, price: 0, bay: data.bay, date: data.date, hour: time };
        }));
      }
    }, [data]);
  
    useEffect(() => {
      let newTotal = 0;
      cartItems.forEach(item => {
        newTotal += item.price;
      });
      setTotal(newTotal);
    }, [cartItems]);
  
    const handleRemoveItem = (itemToRemove) => {
      setCartItems(cartItems.filter(item => item.name !== itemToRemove.name));
      
    };

    const handleConfirmBooking = () => {

        console.log("cartItems are ", cartItems)

        const baseURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_BASE_URL : 'http://localhost:3001' ;
    
        axios.post(`${baseURL}/api/confirm-booking`, {
          cartItems : cartItems
        }, {
          withCredentials: true,
        })
        .then(response => {
            if(response.data.confirmed === true){
                alert(response.data.message)
                navigate(response.data.redirect); 
              } else {
                alert(response.data.message)
                navigate(response.data.redirect)
              }
        })
        .catch(error => {
          console.error('Error:', error);
        });
      };

  return (
    <Box maxWidth={["100%", "100%", "960px"]} margin="0 auto" padding={["2%", "3%", "5% 0"]} marginBottom="5em">
      <Box padding="1em 0" borderBottom="1px solid #D0D0D0" display="flex" alignItems="center">
        <Text as="h1" fontFamily="'Droid Serif', serif" fontSize={["1.5em", "1.75em", "2em"]}>Your Cart</Text>
        <Button onClick={handleContinueBooking} as="a" href="#" variant="link" textDecoration="none" fontFamily="'Montserrat', sans-serif" letterSpacing="-0.015em" fontSize={[".6em", ".7em", ".75em"]} padding="1em" color="#fff" bg="#82ca9c" fontWeight="bold" borderRadius="50px" marginLeft="auto" _hover={{ background: '#82ca9c' }}>
          Continue Booking
        </Button>
      </Box>
      <Box padding="1em 0">
        <Stack spacing={3}>
          {cartItems.map((item, index) => (
            <Box key={index} display="flex" justifyContent="space-between" alignItems="center" border="1px solid #87CEEB" padding={["0.5em", "0.75em", "1em"]} background="#d1f1de">
              <Box display="flex" alignItems="center">
                <Box borderRadius="50%" width="50px" height="50px" border="2px solid" borderColor="#68D391" background="#68D391" color="#fff" display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginRight="0.5em">
                    <Text fontSize="sm" fontWeight={"bold"}>{new Date(item.date).getDate()}</Text>
                    <Text fontSize="sm" fontWeight={"semibold"}>{new Date(item.date).toLocaleString('default', { month: 'short' })}</Text>
                </Box>
                <Box>
                    <Box display="flex" flexDirection={"row"} justifyContent="space-between">
                        <Text fontSize={["sm"]}>Location</Text>
                        <Text marginLeft={1.5} fontWeight="bold" fontSize={["sm"]}> | </Text>
                        <Text marginLeft={1.5} fontSize={["sm"]}>Bay {item.bay}</Text>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <TimeIcon boxSize={4} />
                        <Text marginLeft={1.5} fontSize={["sm", "md", "lg"]}>{item.name}</Text>
                    </Box>
                    <Text fontSize={["sm", "md", "lg"]}>Price : ${item.price}</Text>
                </Box>
              </Box>
              <IconButton aria-label="Delete item" icon={<DeleteIcon />} onClick={() => handleRemoveItem(item)} />
            </Box>
          ))}
        </Stack>
      </Box>
      <Box padding="1em 0" borderTop="1px solid #D0D0D0">
        <Text fontSize="lg" fontWeight="bold" mb="2">Summary</Text>
        <Text fontSize="sm" mb="1">Subtotal: ${total}</Text>
        <Text fontSize="sm" mb="1">Tax: ${total * 0.1}</Text> {/* Assuming tax is 10% */}
        <Text fontSize="sm" mb="1">Total: ${total * 1.1}</Text> {/* Assuming tax is 10% */}
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Button onClick={handleConfirmBooking} as="a" href="#" variant="link" textDecoration="none" fontFamily="'Montserrat', sans-serif" letterSpacing="-0.015em" fontSize={["16px"]} padding="1em" color="#fff" bg="#82ca9c" fontWeight="bold" borderRadius="50px" _hover={{ background: '#82ca9c' }}>
            Confirm Booking
        </Button>
      </Box>
      <Box paddingBottom={150} width={["100%", "100%", "35%"]}>
      </Box>
    </Box>
  );
};

export default ShoppingCart;