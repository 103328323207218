import React, { useState } from "react";
import { Box, useColorModeValue, Flex, chakra } from '@chakra-ui/react';
import { MdLocationOn } from "react-icons/md";

const FacilityList = () => {
  const [value, setValue] = useState("1");

  const locations = [
    { id: "1", name: "Chicago" },
    { id: "2", name: "Reston" }
    // Add more locations as needed
  ];

  const bg = useColorModeValue("#fff", "#fff");
  const selectedBg = useColorModeValue("#d1f1de", "green.800");

  return (
    <Box p={{ base: 3, md: 5 }} rounded={'md'} bg={bg} boxShadow={'sm'}>
      <Flex direction="column" display="flex" overflowY="auto" marginTop={3}>
        <Flex justifyContent="space-between">
          <Flex>
            <MdLocationOn fontSize={{ base: 20, md: 25 }} />
            <chakra.span mt={-1} ml={1}> Select the facility</chakra.span>
          </Flex>
        </Flex>
      </Flex>
      <Flex direction={{ base: "column", md: "row" }} p={5} _dark={{ bg: "#3e3e3e" }} justifyContent="space-between" flexWrap="wrap">
        {locations.map((location) => (
          <Box key={location.id} p={3} borderRadius="md" border="1px solid #87CEEB" mb={{ base: 2, md: 0 }} bg={value === location.id ? selectedBg : ""}
            onClick={() => setValue(location.id)} cursor="pointer" flexBasis={{ base: "100%", sm: "50%", md: "33%" }} textAlign="center">
            {location.name}
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default FacilityList;