import {
  Box,
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useColorModeValue,
  Stack,
  Center,
  Image
} from '@chakra-ui/react'
import React, { useContext } from 'react';
import AuthContext from '../AuthContext'

const NavLink = (props) => {
  const { children } = props

  return (
    <Box
      as="a"
      px={2}
      py={1}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}
      href={'#'}>
      {children}
    </Box>
  )
}

export default function HeaderDashboard({message}) {

  const { logout } = useContext(AuthContext);

  const logoutSubmit = async (e) => {
    e.preventDefault();
    logout()
  };

  return (
    <>
      <Box bg={useColorModeValue('#87CEEB', '#87CEEB')} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
        <Image src="../birdiehouse_logo.svg" width="250px" height="50px" />

          <Flex alignItems={'center'}>
            <Stack direction={'row'} spacing={7}>
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={'full'}
                  variant={'link'}
                  cursor={'pointer'}
                  minW={0}>
                  <Avatar
                    name={message.FirstName + ' ' + message.LastName}
                    size={'sm'}
                    src={'https://bit.ly/broken-link'}
                  />
                </MenuButton>
                <MenuList alignItems={'center'}>
                  <br />
                  <Center>
                    <Avatar
                      name={message.FirstName + ' ' + message.LastName}
                      size={'2xl'}
                      src={'https://bit.ly/broken-link'}
                    />
                  </Center>
                  <br />
                  <Center>
                    <p>{message.FirstName + ' ' + message.LastName}</p>
                  </Center>
                  <br />
                  <MenuDivider />
                  <MenuItem>My Profile</MenuItem>
                  <MenuItem>Account Settings</MenuItem>
                  <MenuItem onClick={logoutSubmit}>Logout</MenuItem>
                </MenuList>
              </Menu>
            </Stack>
          </Flex>
        </Flex>
      </Box>
    </>
  )
}