import { Box, Flex, useBreakpointValue, VStack, StackDivider, Heading, Text, Stack, LinkBox, LinkOverlay } from '@chakra-ui/react';

const IphoneX = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  if (isMobile) {
    return null; // Don't render anything on mobile devices
  }

  /*
  function Feature({ title, desc, ...rest }) {
    return (
      <Box p={5} shadow="md" borderWidth="1px" {...rest}>
        <Heading fontSize="xl">{title}</Heading>
        <Text mt={2}>{desc}</Text>
      </Box>
    );
  }
  */
  return (
    <Flex align="center" justify="center">
      <Box className="iphonex" height="500px" width="600px" position="relative">
        <Box
          className="iphonex__case"
          position="absolute"
          background="transparent"
          width="320px"
          height="600px"
          border="11px solid black"
          boxSizing="border-box"
          borderRadius="35px"
          right="25%"
        >
          <Box
            className="iphonex__top"
            position="absolute"
            background="transparent"
            height="15px"
            width="208px"
            borderTopRightRadius="20px"
            borderTopLeftRadius="20px"
          >
            <Box
              className="iphonex__time"
              position="absolute"
              top="3px"
              left="14px"
              fontSize="10px"
              fontWeight="bold"
              fontFamily="'Roboto', sans-serif"
            >
              
            </Box>
            <Box className="fa fa-wifi iphonex__wifi" position="absolute" right="25px" fontSize="10px" top="3px" />
            <Box
              className="fa fa-battery-quarter iphonex__battery"
              position="absolute"
              right="8px"
              fontSize="10px"
              top="3px"
            />
          </Box>
          <Box
            className="iphonex__notch"
            background="black"
            height="15px"
            width="115px"
            position="absolute"
            left="90px"
            borderBottomLeftRadius="8px"
            borderBottomRightRadius="8px"
          >
            <Box className="iphonex__speaker" background="rgb(51, 51, 51)" width="30px" height="6px" borderRadius="8px" position="absolute" left="43px" />
            <Box className="iphonex__camera" background="rgb(51, 51, 51)" width="7px" height="7px" borderRadius="8px" position="absolute" left="80px" />
          </Box>
          <Box
            className="iphonex__screen"
            width="210px"
            height="435px"
            background="transparent"
            borderBottomRightRadius="20px"
            borderBottomLeftRadius="20px"
            top="15px"
            position="absolute"
          />
        </Box>
      </Box>
      <Stack spacing={4} marginTop={20}>
      <LinkBox as='article' maxW='sm' p='5' borderWidth='1px' rounded='md' _hover={{ backgroundColor: "#f5f5f5" }}>
      <Heading size='md' my='2'>
        <LinkOverlay href='#'>
          Get the facilities real time bay status
        </LinkOverlay>
      </Heading>
      <Text>
          Know exactly what to expect when you arrive at a facility. Say goodbye to any doubts about entry and play opportunities. Upon reaching the destination, hit "Request Unlock," and you're all squared away!
          
      </Text>
      </LinkBox>
      <LinkBox as='article' maxW='sm' p='5' borderWidth='1px' rounded='md' _hover={{ backgroundColor: "#f5f5f5" }}>
      <Heading size='md' my='2'>
        <LinkOverlay href='#'>
        Choose your bay
        </LinkOverlay>
      </Heading>
      <Text>
      The Birdie House has 3 bays. Choose whichever is available.
      </Text>
      </LinkBox>
      <LinkBox as='article' maxW='sm' p='5' borderWidth='1px' rounded='md' _hover={{ backgroundColor: "#f5f5f5" }}>
      <Heading size='md' my='2'>
        <LinkOverlay href='#'>
        Play as long as you'd like
        </LinkOverlay>
      </Heading>
      <Text>
      As a member, enjoy your playtime. When finished, just click "End Session" and the bay will be secured for the next individual.
      </Text>
      </LinkBox>
    </Stack>
    </Flex>
  );
};

export default IphoneX;
