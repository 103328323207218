import React, { useState, useEffect } from 'react';
import { Box, Flex, Button, chakra } from "@chakra-ui/react";
import { TbGolf } from 'react-icons/tb';
import { useSwipeable } from 'react-swipeable';

const Bay = ({ bayNumber, selectedBay, setSelectedBay }) => (
  <Box
    w="full"
    maxW="sm"
    mx={2}
    px={4}
    py={3}
    _dark={{ bg: "gray.800" }}
    shadow="md"
    rounded="md"
    border="1px solid #68D391"
    bg={bayNumber === selectedBay ? '#d1f1de' : 'white'}
    onClick={() => setSelectedBay(bayNumber)}
  >
    <Flex justifyContent="center" alignItems="center">
      <chakra.span fontSize="md" color="gray.800">Bay</chakra.span>
    </Flex>
    <Box display="flex" justifyContent="center">
      <chakra.h1 fontSize="lg" fontWeight="bold" mt={2} color="gray.800" _dark={{ color: "white" }}>{bayNumber}</chakra.h1>
    </Box>
    <Box>
      <Flex alignItems="center" mt={2} color="gray.700" _dark={{ color: "gray.200" }}></Flex>
    </Box>
  </Box>
);

const BayBooking = ({ onBayChange }) => {
  const totalBays = 9;
  const baysPerPage = 3;
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedBay, setSelectedBay] = useState(1);

  useEffect(() => {
    if (selectedBay !== null) {
      onBayChange(selectedBay);
    }
  }, [selectedBay, onBayChange]);

  const handleScrollNext = () => setCurrentPage((currentPage + 1) % Math.ceil(totalBays / baysPerPage));
  const handleScrollPrev = () => setCurrentPage((currentPage - 1 + Math.ceil(totalBays / baysPerPage)) % Math.ceil(totalBays / baysPerPage));

  const currentBaysStart = currentPage * baysPerPage + 1;
  const currentBaysEnd = Math.min((currentPage + 1) * baysPerPage, totalBays);

  const handlers = useSwipeable({
    onSwipedLeft: () => handleScrollNext(),
    onSwipedRight: () => handleScrollPrev(),
  });

  return (
    <Box {...handlers}>
      <Flex direction="column" display="flex" overflowY="auto" marginTop={3}>
        <Flex justifyContent="space-between">
          <Flex>
            <TbGolf fontSize={25} />
            <chakra.span> Select a bay</chakra.span>
          </Flex>
        </Flex>
      </Flex>
      <Flex marginTop={2}>
        <Button onClick={handleScrollPrev}>&lt;</Button>
        <chakra.span padding="8px">Bay {currentBaysStart}-{currentBaysEnd}</chakra.span>
        <Button onClick={handleScrollNext}>&gt;</Button>
      </Flex>
      <Flex direction="row" p={5} _dark={{ bg: "#3e3e3e" }} justifyContent="space-between">
        {[...Array(baysPerPage)].map((_, i) => {
          const bayNumber = currentPage * baysPerPage + i + 1;
          if (bayNumber > totalBays) return null;
          return <Bay key={bayNumber} bayNumber={bayNumber} selectedBay={selectedBay} setSelectedBay={setSelectedBay} />;
        })}
      </Flex>
    </Box>
  );
};

export default BayBooking;