// AuthContext.js
import React, { createContext, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const baseURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_BASE_URL : 'http://localhost:3001' ;
  
  const navigate = useNavigate();

  const login = async(userLogin, loginType) => {
    try {
        const response = await axios.post(`${baseURL}/api/login-form`, {
          userLogin: userLogin,
          type: loginType
        }, {
          withCredentials: true, // This is important to include the session cookie
        })

        if(response.data.success === true) {
            console.log("Session user for login ", response.data)
            navigate('/verify');
        } else {
            navigate(response.data.redirect);
        }
    } catch (error) {
        alert(error.response.data.message)
        navigate(error.response.data.redirect);  
      }
  }

  const register = async (firstName, lastName, phone, email) => { 
    try {
      const response = await axios.post(`${baseURL}/api/register-form`, {
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        email: email
      }, {
        withCredentials: true, // This is important to include the session cookie
      })
      
      if(response.data.success === true) {
          navigate('/verify');
      } else {
          alert(response.data.message)
          navigate(response.data.redirect);
      }
      
    } catch (error) {
        console.error('Error logging in:', error);
    }
  }

  const verify = async (authCode) => {
    try {
        const response = await axios.post(`${baseURL}/api/verify-form`, {
                auth_code: authCode
                }, {
                  withCredentials: true, // This is important to include the session cookie
                })
                
                if(response.data.verify_status === true) {
                  navigate(response.data.redirect);
                }

        } catch (error) {
            console.log("error in verify", error.response.data)
            alert(error.response.data.message)
            navigate(error.response.data.redirect);
        }
  }

  const logout = async () => {
    try {
      const response = await axios.post(`${baseURL}/api/logout`, {
              
              }, {
                withCredentials: true, // This is important to include the session cookie
              })
              
              if(response.data.success === true) {
                navigate(response.data.redirect);
              }

      } catch (error) {
          console.log("error in logout", error.response.data)
          alert(error.response.data.message)
      }
}

  return (
    <AuthContext.Provider value={{ user, login, register, verify, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;