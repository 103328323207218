import {
  Box,
  SimpleGrid,
  Text,
  Flex
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react';
import { FaCheck } from "react-icons/fa";
import { PiWarningCircleLight } from "react-icons/pi";
import {CalendarIcon } from "@chakra-ui/icons";
import axios from 'axios';

export default function ListBays() {
  const [bays, setBays] = useState([]);
  const baseURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_BASE_URL : 'http://localhost:3001' ;

  useEffect(() => {
    const fetchData = async () => {
    let date = new Date();
    let currentHour = date.getHours();
    console.log("current hour is ",currentHour)

      try {
        const response = await axios.get(`${baseURL}/api/bays`, {
          params: {
            currentHour: currentHour
          },
          withCredentials: true,
        })

        setBays(response.data.message);
        console.log('Bays:', response.data.message)
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <Box maxW="7xl" mx={'auto'} pt={2.5} px={{ base: 2, sm: 12, md: 17 }} paddingBottom={250}>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 3, lg: 8 }}>
        {bays.map((bay) => {
          const currentDate = new Date();
          const currentHour = currentDate.getHours();
          const currentMinutes = currentDate.getMinutes();
          const nextAvailableInHours = bay.EarliestBookedHour ? bay.EarliestBookedHour - currentHour : null;
          const nextAvailableInMinutes = nextAvailableInHours * 60 - currentMinutes;
          return (
            <Box 
              key={bay.BayNumber} 
              display="flex" 
              justifyContent="space-between" 
              alignItems="center" 
              border="1px solid #87CEEB" 
              padding={["0.5em", "0.75em", "1em"]} 
              background={!bay.isActive ? "#d1f1de" : "#d1f1de"}
              borderRadius="10px"
            >
              <Box display="flex" alignItems="center">
                <Box 
                  borderRadius="50%" 
                  width="50px" 
                  height="50px" 
                  border="2px solid" 
                  borderColor={!bay.isActive ? "#68D391" : "#68D391"} 
                  background={!bay.isActive ? "#68D391" : "#68D391"} 
                  color="#fff" 
                  display="flex" 
                  flexDirection="column" 
                  justifyContent="center" 
                  alignItems="center" 
                  marginRight="0.5em"
                >
                  <Text fontSize="xs" fontWeight={"bold"}>Bay</Text>
                  <Text fontSize="sm" fontWeight={"bold"}>{bay.BayNumber}</Text>
                </Box>
                <Box>
                  <Text 
                    color={!bay.isActive ? "#2C7C58" : "#FF0000"} 
                    fontWeight={"bold"}
                    fontSize={["sm", "md", "lg"]}>
                      {!bay.isActive ? 'Available' : 'Not Available'}
                  </Text>
                  {nextAvailableInHours !== null && nextAvailableInHours >= 0 && 
                    <Flex alignItems="center">
                      <CalendarIcon boxSize={4} color={"#2C7C58"} />
                      <Text marginLeft={1.5} color="#2C7C58" fontWeight={"bold"} fontSize={["xs"]}>
                        Next booking {nextAvailableInHours === 0 ? 'is Now' : nextAvailableInHours === 1 ? `in ${nextAvailableInMinutes} minute(s)` : `in ${nextAvailableInHours} hour(s)`}
                      </Text>
                    </Flex>
                  }
                </Box>
              </Box>
              <Box>
                {!bay.isActive ? <FaCheck color="green" size={'1em'} /> : <PiWarningCircleLight color="red" size={'1.5em'} />}
              </Box>
            </Box>
          )
        })}
      </SimpleGrid>
    </Box>
  )
}