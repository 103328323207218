import React, { useState, useContext, useEffect } from 'react';
import {Box,Stack,Text,Center,Button,HStack,PinInput,PinInputField } from '@chakra-ui/react';
import AuthContext from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const OTPForm = () => {
  const [message, setMessage] = useState('');
  const navigate = useNavigate()

  const baseURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_BASE_URL : 'http://localhost:3001' ;
  
  useEffect(() => { 
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/verify-form`, {
          withCredentials: true,
        })
        setMessage(response.data.message);
        
      } catch (error) {
          alert(error.response.data.message)
          navigate(error.response.data.redirect);
      }
    };
    fetchData();
  }, [navigate]);

  const [pinValues, setPinValues] = useState(['', '', '', '', '', '']);

  const handlePinChange = (index, value) => {
    const newPinValues = [...pinValues];
    newPinValues[index] = value;
    setPinValues(newPinValues);
  };

  const { verify } = useContext(AuthContext);

  const handleSubmit = async () => {

    const concatenatedValue = pinValues.join('');
    let authCode = concatenatedValue
    // Your verification logic here, using concatenatedValue
    
    if(authCode.length === 6) {
      verify(authCode)
    } else {
      alert("Please enter all digits of the code")
    }
  };

  return (
    <>
    <form action="" className="otp-form" name="otp-form">
        <Box maxW="400px" mx="auto" textAlign="center" fontFamily="">
          <Text fontWeight="bold">Verification Code</Text>
          <Text fontSize="12px" color="#118a44" className="info">
            A verification has been sent to your phone number {message} 
          </Text>
          <Text fontSize="12px" color="initial" textAlign="initial" fontWeight="bold" className="msg">
            Please enter your code to verify
          </Text>
        </Box>
        <Stack direction="row" spacing="10px" justifyContent="center" padding="40px" maxW="400px" mx="auto" margintop="40px" boxShadow="0px 0px 8px 0px #02025044">
          <HStack>
            <PinInput className="pin-input">
              {pinValues.map((value, index) => (
                <PinInputField
                  key={index}
                  value={value}
                  type="tel"
                  onChange={(e) => handlePinChange(index, e.target.value)}
                />
              ))}
            </PinInput>
          </HStack>
        </Stack>
        <Center>
          <Button colorScheme="green" onClick={handleSubmit}>
            Verify
          </Button>
        </Center>
      </form>
    </>
  );
};

export default OTPForm;