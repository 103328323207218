import {
    Box,
    Table,
    Thead,
    Tr,
    Tbody,
    Td,
    Th,
    Button,
    Text,
    HStack,
    Flex,
    Spacer
  } from "@chakra-ui/react";
  import { format, addDays, startOfDay, isSameDay } from "date-fns";
  import { useState, useEffect } from "react";
  import { CalendarIcon } from '@chakra-ui/icons'
  
  const CalendarDate = ({ onDateChange }) => {
    const [selectedDate, setSelectedDate] = useState(startOfDay(new Date()));
    const [currentDate, setCurrentDate] = useState(startOfDay(new Date()));
    const [dates, setDates] = useState([]);
  
    useEffect(() => {
      const newDates = [];
      for (let i = 0; i < 5; i++) {
        newDates.push(addDays(currentDate, i));
      }
      setDates(newDates);
    }, [currentDate]);

    useEffect(() => {
      onDateChange(selectedDate);
    }, [selectedDate, onDateChange]);
  
    return (
      <Flex direction="column" display="flex" overflowY="auto" marginTop={3}>
        <HStack marginBottom={2}>
            <CalendarIcon /> 
            <Text>Select a date</Text>
            <Spacer />
        </HStack>
        <HStack justify="space-between">
          <HStack>
            <Button onClick={() => setCurrentDate(addDays(currentDate, -5))}>&lt;</Button>
            <Text>{format(currentDate, "MMM yyyy")}</Text>
            <Button onClick={() => setCurrentDate(addDays(currentDate, 5))}>&gt;</Button>
          </HStack>
        </HStack>
        <Table>
          <Thead>
            <Tr>
              {dates.map((date, index) => (
                <Th key={index}>{format(date, "E")}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              {dates.map((date, index) => (
                <Td
                  key={index}
                  onClick={() => setSelectedDate(date) }
                >
                  <Box
                    borderRadius="50%"
                    w="30px"
                    h="30px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    border={isSameDay(selectedDate, date) ? "2px solid #68D391" : "none"}
                  >
                    {format(date, "dd")}
                  </Box>
                </Td>
              ))}
            </Tr>
          </Tbody>
        </Table>
      </Flex>
    );
  };
  export default CalendarDate;